import React from 'react';
import { Form, Input } from 'antd';
import { humanizeText } from '../../../util/text';

const InputField = ({ attribute, required = true, label = null, type = "text", help = null, message = null }) => {
  const displayLabel = label === null ? humanizeText(attribute) : label;
  const rules = required ? [{ required: true, message: message || `Please enter a ${displayLabel}` }] : [];
  return (
    <Form.Item
      label={displayLabel}
      name={attribute}
      extra={help}
      rules={rules}>
      <Input type={type} />
    </Form.Item>
  )
}

export default InputField