export const BASE_LINK_ROUTES = [
  {
    path: '/',
    breadcrumbName: 'Dashboard',
  },
  {
    path: '/links',
    breadcrumbName: 'Links',
  },
];

export const BASE_CONVERSION_ROUTES = [
  {
    path: '/',
    breadcrumbName: 'Dashboard',
  },
  {
    path: '/conversions',
    breadcrumbName: 'Transactions',
  },
];


export const BASE_SUBSCRIPTION_ROUTES = [
  {
    path: '/',
    breadcrumbName: 'Dashboard',
  },
  {
    path: '/subscriptions',
    breadcrumbName: 'Subscriptions',
  },
];


export const BASE_PAYOUT_ROUTES = [
  {
    path: '/',
    breadcrumbName: 'Dashboard',
  },
  {
    path: '/payouts',
    breadcrumbName: 'Payouts',
  },
];

export const BASE_ACCOUNT_ROUTES = [
  {
    path: '/',
    breadcrumbName: 'Dashboard',
  },
  {
    path: '/account',
    breadcrumbName: 'Account',
  },
];

export const BASE_MARKETING_ROUTES = [
  {
    path: '/',
    breadcrumbName: 'Dashboard',
  },
  {
    path: '/marketing',
    breadcrumbName: 'Marketing',
  },
];
