import {
  ACCEPT_TERM_BEGIN,
  ACCEPT_TERM_FAILURE,
  ACCEPT_TERM_SUCCESS,
  ACCEPT_TERM_CLEAR,
} from '../actions/termActions';

const initialState = {
  success: null,
  loading: false,
  error: null,
};

export default function termReducer(state = initialState, action) {
  switch (action.type) {
    case ACCEPT_TERM_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case ACCEPT_TERM_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true
      };

    case ACCEPT_TERM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case ACCEPT_TERM_CLEAR:
      return {
        ...state,
        loading: false,
        success: null,
        error: null,
      };

    default:
      return state;
  }
}