import React from 'react';
import { Notifier } from '@airbrake/browser';
import { Result, Button } from 'antd';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, env: process.env.NODE_ENV};
    if (process.env.NODE_ENV !== 'development') {
      this.airbrake = new Notifier({
        projectId: 314172,
        projectKey: 'bc0487cf5a7376c0b90030b3eafbc97f'
      });
      
      this.airbrake.addFilter(function(notice) {
        notice.context.environment = process.env.NODE_ENV;
        return notice;
      });
    }
  }

  componentDidCatch = (error, info) => {
    // Display fallback UI
    this.setState({ hasError: true });
    // Send error to Airbrake
    const { env } = this.state;
    if (env !== 'development') {
      this.airbrake.notify({
        error: error,
        params: {info: info}
      });
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Result
              status="warning"
              title="Whoops, that's an error."
              subTitle="Something went wrong on our end and your request couldn't be completed. We've been notified of the issue and we're working quickly to resolve it."
              extra={<Button type="primary" href="/">Back Home</Button>}
            />
    }
    return this.props.children;
  }
}

export default ErrorBoundary