export function capitalizeText(string) {
  if (string === null || string === undefined) {
    return '';
  }
  return string.replace(/^./, string[0].toUpperCase());
}

export function getGetOrdinal(n) {
  var s=["th","st","nd","rd"],
      v=n%100;
  return n+(s[(v-20)%10]||s[v]||s[0]);
}

export function humanizeText(str) {
  return str
      .replace(/^[\s_]+|[\s_]+$/g, '')
      .replace(/[_\s]+/g, ' ')
      .replace(/^[a-z]/, function(m) { return m.toUpperCase(); });
}