import {
  FETCH_PROFILE_BEGIN,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  UPDATE_PROFILE_BEGIN,
  UPDATE_PROFILE_FAILURE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_CLEAR
} from '../actions/profileActions';

const initialState = {
  profile: null,
  loading: false,
  error: null
};

export default function profileReducer(state = initialState, action) {
  switch(action.type) {
    case FETCH_PROFILE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload.profile,
      };

    case FETCH_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        profile: null
      };

    case UPDATE_PROFILE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
  
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload.profile,
        success: true,
      };

    case UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    
    case UPDATE_PROFILE_CLEAR:
      return {
        ...state,
        loading: false,
        error: null,
        success: null,
      };

    default:
      return state;
  }
}