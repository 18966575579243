import React from "react"
import { connect } from "react-redux";

import { Skeleton, message, Form, Row, Col, Button } from 'antd';
import AddressForm from '../../components/shared/AddressForm';
import ErrorDisplay from '../../components/shared/ErrorDisplay';
import { fetchProfile, updateProfileClear, updateProfile } from "../../actions/profileActions";
import InputField from '../shared/inputs/InputField';

class ContactForm extends React.Component {

  componentDidMount() {
    this.props.dispatch(fetchProfile());
  }

  onFinish = values => {
    const { profile } = this.props;
    if (profile.address && profile.address.id !== undefined) {
      values.address_id = profile.address.id;
    }
    this.props.dispatch(updateProfile(values));
  };

  render() {
    const { profile, error, success, loading } = this.props;

    if (success) {
      this.props.dispatch(updateProfileClear());
      message.success(`Profile updated successfully`);
    }

    if (profile === null || profile === undefined) {
      return (
        <Skeleton />
      )
    }
    else {
      const initialValues = Object.assign(profile, profile.address);
      return (
        <Form layout="vertical" onFinish={this.onFinish} initialValues={initialValues}>
          <ErrorDisplay error={error} />
          <Row gutter={16}>
            <Col sm={8} xs={24}>
              <InputField
                label="First name"
                attribute="first_name"
              />
            </Col>
            <Col sm={8} xs={24}>
              <InputField
                label="Last name"
                attribute="last_name"
              />
            </Col>

            <Col sm={8} xs={24}>
              <InputField
                label="Phone"
                attribute="phone"
              />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col sm={12} xs={24}>
              <InputField
                label="Company name"
                attribute="company"
              />
            </Col>
            <Col sm={12} xs={24}>
              <InputField
                label="Website"
                attribute="website"
              />
            </Col>
          </Row>

          <AddressForm address={profile.address} required={true} />

          <Button htmlType="submit" type="primary" loading={loading}>
            Save
          </Button>
        </Form>
      );
    }
  }
}

const mapStateToProps = state => ({
  profile: state.profile.profile,
  loading: state.profile.loading,
  error: state.profile.error,
  success: state.profile.success
});

export default connect(mapStateToProps)(ContactForm);