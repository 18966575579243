import axios from 'axios';
import { authHeader } from '../util/api';

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export const FETCH_DASHBOARD_BEGIN = 'FETCH_DASHBOARD_BEGIN';
export const FETCH_DASHBOARD_SUCCESS = 'FETCH_DASHBOARD_SUCCESS';
export const FETCH_DASHBOARD_FAILURE = 'FETCH_DASHBOARD_FAILURE';

export const fetchDashboardBegin = () => ({
  type: FETCH_DASHBOARD_BEGIN
});

export const fetchDashboardSuccess = (dashboard) => ({
  type: FETCH_DASHBOARD_SUCCESS,
  payload: { dashboard }
});

export const fetchDashboardFailure = error => ({
  type: FETCH_DASHBOARD_FAILURE,
  payload: { error }
});

export function fetchDashboard(value) {
  return dispatch => {
    dispatch(fetchDashboardBegin());
    return axios.get(`${BASE_API_URL}/dashboard.json?start_date=${value[0].format("DD/MM/YYYY")}&end_date=${value[1].format("DD/MM/YYYY")}`, {
      headers: authHeader()
    })
      .then(json => {
        dispatch(fetchDashboardSuccess(json.data));
        return json.data;
      })
      .catch(error => dispatch(fetchDashboardFailure(error)));
  };
}

