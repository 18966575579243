import axios from 'axios';
import { authHeader } from '../util/api';

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export const FETCH_MARKETING_RESOURCES_BEGIN   = 'FETCH_MARKETING_RESOURCES_BEGIN';
export const FETCH_MARKETING_RESOURCES_SUCCESS = 'FETCH_MARKETING_RESOURCES_SUCCESS';
export const FETCH_MARKETING_RESOURCES_FAILURE = 'FETCH_MARKETING_RESOURCES_FAILURE';

export const fetchMarketingResourcesBegin = () => ({
  type: FETCH_MARKETING_RESOURCES_BEGIN
});

export const fetchMarketingResourcesSuccess = (resources, totalItems, popularTags, products) => ({
  type: FETCH_MARKETING_RESOURCES_SUCCESS,
  payload: { resources, totalItems, popularTags, products }
});

export const fetchMarketingResourcesFailure = error => ({
  type: FETCH_MARKETING_RESOURCES_FAILURE,
  payload: { error }
});

export function fetchMarketingResources(page = 1, query, type, product) {
  return dispatch => {
    dispatch(fetchMarketingResourcesBegin());
    let queryString = '';
    let typeString = '';
    let productString = '';
    if (query !== undefined && query !== '') {
      queryString = `&q[title_cont]=${query}`
    }
    if (type !== undefined && type !== '') {
      typeString = `&q[kind_eq]=${type}`
    }
    if (product !== undefined && product !== '') {
      productString = `&q[product_id_eq]=${product}`
    }
    return axios.get(`${BASE_API_URL}/marketing_resources.json?page=${page}${queryString}${typeString}${productString}`, { headers: authHeader() })
      .then(json => {
        dispatch(fetchMarketingResourcesSuccess(json.data.marketing_resources, json.data.total_entries, json.data.popular_tags, json.data.products));
        return json.data.marketing_resources;
      })
      .catch(error => dispatch(fetchMarketingResourcesFailure(error)));
  };
}

export async function fetchMarketingResourceUrl(id) {
  let json = await axios.get(`${BASE_API_URL}/marketing_resources/${id}.json`, {
    headers: authHeader()
  })
  return json.data.url;
}


