import { buildSortString, buildFilterString } from '../util/api';
import axios from 'axios';
import { authHeader } from '../util/api';

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export const FETCH_LINKS_BEGIN   = 'FETCH_LINKS_BEGIN';
export const FETCH_LINKS_SUCCESS = 'FETCH_LINKS_SUCCESS';
export const FETCH_LINKS_FAILURE = 'FETCH_LINKS_FAILURE';

export const fetchLinksBegin = () => ({
  type: FETCH_LINKS_BEGIN
});

export const fetchLinksSuccess = (links, availableProducts, availableKinds, totalItems) => ({
  type: FETCH_LINKS_SUCCESS,
  payload: { links, availableProducts, availableKinds, totalItems }
});

export const fetchLinksFailure = error => ({
  type: FETCH_LINKS_FAILURE,
  payload: { error }
});

export function fetchLinks(page = 1, pageSize = 10, filters, sorter, query) {
  return dispatch => {
    dispatch(fetchLinksBegin());
    let sortString = buildSortString(sorter);
    let filterString = buildFilterString(filters);
    let queryString = '';
    if (query !== undefined && query !== '') {
      queryString = `&q[link_hash_cont]=${query}`
    }
    return axios.get(`${BASE_API_URL}/links.json?page=${page}&per_page=${pageSize}${sortString}${filterString}${queryString}`, { headers: authHeader() })
      .then(json => {
        dispatch(fetchLinksSuccess(json.data.links, json.data.available_products, json.data.available_kinds, json.data.total_entries));
        return json.data.links;
      })
      .catch(error => dispatch(fetchLinksFailure(error)));
  };
}
