import React from "react"
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Layout, Menu, Dropdown, Alert } from 'antd';
import { UserOutlined, ControlOutlined, LogoutOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { logout } from "../../actions/userActions";

class Header extends React.Component {

  doLogout = () => {
    this.props.dispatch(logout());
  };

  render() {
    const user = JSON.parse(localStorage.getItem('user'));
    const impersonated = user && user.impersonated;

    const menu = (
      <Menu>
        <Menu.Item>
          <Link to='/account'>
            <ControlOutlined style={{ marginRight: '.75em' }} />
            Account settings
          </Link>
        </Menu.Item>
        <Menu.Item>
          <a href="/login" onClick={this.doLogout}>
            <LogoutOutlined style={{ marginRight: '.75em' }} />
            Log out
          </a>
        </Menu.Item>
      </Menu>
    );

    return (
      <Layout.Header style={{ background: '#fff', position: 'fixed', zIndex: 1, width: '100%', paddingRight: '20px' }} >
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse' }}>
          {this.props.mobileView && !this.props.sidebarOpen &&
            <MenuUnfoldOutlined
              onClick={() => this.props.openSidebar(true)}
              style={{ fontSize: '20px', marginTop: '20px', left: 20, position: 'absolute' }} />
          }
          <Dropdown overlay={menu}>
            <a className="ant-dropdown-link">
              <UserOutlined style={{ fontSize: '20px' }} />
            </a>
          </Dropdown>
          {impersonated && <Alert
            message="impersonation mode"
            style={{ margin: '15px 0px' }}
          />}
        </div>
      </Layout.Header>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});
export default connect(mapStateToProps)(Header);
