import React from "react"
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { BASE_PAYOUT_ROUTES } from '../util/routes';

import PageHeader from './shared/PageHeader';
import List from './payouts/List';
import { fetchPayouts } from "../actions/payoutActions";

const TITLE = 'Payouts'

class Payouts extends React.Component {
  state = {
    page: 1,
    pageSize: 10,
    filters: {},
    sorter: {},
    query: '',
  };

  componentDidMount() {
    this.props.dispatch(fetchPayouts());
  }

  handleTableChange = (pagination, filters, sorter) => {
    const page = pagination.current;
    const pageSize = pagination.pageSize;
    let { query } = this.state;
    this.props.dispatch(fetchPayouts(page, pageSize, filters, sorter, query));
    this.setState({
      page, filters, sorter, pageSize
    });
  };

  render() {
    const { loading, payouts, totalItems } = this.props;
    const { page } = this.state;

    return (
      <>
        <PageHeader title={TITLE} routes={BASE_PAYOUT_ROUTES} />
        <div className="content-holder" style={{ background: '#fff' }}>
          <List payouts={payouts} totalPayouts={totalItems} onTableChange={this.handleTableChange} loading={loading} page={page} />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  payouts: state.payouts.items,
  totalItems: state.payouts.totalItems,
  loading: state.payouts.loading,
});

export default connect(mapStateToProps)(withRouter(Payouts));