import React from "react";
import { Redirect } from 'react-router-dom';
import { Form, Input, Button, Card, Alert, Spin, message } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import { connect } from 'react-redux';
import { resetPassword } from "../actions/userActions";

import logo from '../images/logo_large.png'

class ResetPassword extends React.Component {

  state = {
    token: null
  }

  componentDidMount() {
    const { location } = this.props
    const hash = location.search
    const parts = hash.split('?reset_password_token=')
    if (!parts[1]) {
      this.props.history.push('/forgot-password')
    }
    this.setState({ token: parts[1] })
  }

  componentDidUpdate(prevProps, _prevState) {
    if (prevProps.success !== this.props.success) {
      if (this.props.success) {
        message.success('Password updated successfully');
        window.location.href = '/login';
      }
    }
  }

  handleSubmit = values => {
    const { password, password_confirmation } = values;
    const { token } = this.state
    values.reset_password_token = token
    this.props.dispatch(resetPassword(values));
  };

  render() {
    const { error, loading, success } = this.props;

    return (
      <div className="login-container">
        <Card title="CHANGE YOUR PASSWORD" style={{ width: 300 }}>
          {error ? <Alert message="Invalid Token" type="error" style={{ marginBottom: '1em' }} /> : ''}
          <Form layout='vertical' onFinish={this.handleSubmit}>
            <img src={logo} style={{ marginBottom: '2em' }} alt="Umbrella" />
            <Form.Item name="password" rules={[{ required: true, message: 'Please enter your password' }]}>
              <Input
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Password"
                type="password"
              />
            </Form.Item>
            <Form.Item name="password_confirmation" rules={[{ required: true, message: 'Please enter your password' }]}>
              <Input
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Confirm Password"
                type="password"
              />
            </Form.Item>
            <Button block type="primary" htmlType="submit" className="login-form-button" disabled={loading}>
              Save
            </Button>
            <div style={{marginTop: '1rem'}}></div>
            <div>
              <a style={{ float: 'left' }} href="/login">
                Login
              </a>
            </div>
          </Form>
        </Card>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  user: state.auth.user,
  loading: state.auth.loading,
  error: state.auth.error,
  success: state.auth.success
});
export default connect(mapStateToProps)(ResetPassword);