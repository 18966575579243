import {
  FETCH_LINKS_BEGIN,
  FETCH_LINKS_SUCCESS,
  FETCH_LINKS_FAILURE
} from '../actions/linkActions';

const initialState = {
  items: [],
  availableProducts: [],
  availableKinds: [],
  loading: false,
  error: null
};

export default function linkReducer(state = initialState, action) {
  switch(action.type) {
    case FETCH_LINKS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_LINKS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.links,
        availableProducts: action.payload.availableProducts,
        availableKinds: action.payload.availableKinds,
        totalItems: action.payload.totalItems,
      };

    case FETCH_LINKS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: []
      };

    default:
      return state;
  }
}