import { combineReducers } from "redux";
import auth from "./authReducer";
import profile from "./profileReducer";
import marketingResources from "./marketingResourceReducer";
import links from "./linkReducer";
import conversions from "./conversionReducer";
import subscriptions from "./subscriptionReducer";
import dashboard from "./dashboardReducer";
import terms from "./termReducer";
import affiliates from "./affiliateReducer"
import payouts from "./payoutReducer"

export default combineReducers({
  auth, 
  profile,
  marketingResources,
  links,
  conversions,
  subscriptions,
  dashboard,
  terms,
  affiliates,
  payouts
});