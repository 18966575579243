import React from "react"
import PropTypes from "prop-types"
import { Table, Typography } from 'antd';

const { Paragraph } = Typography

class List extends React.Component {
  state = {
    data: this.props.contacts,
    pagination: {
      current: 1,
      showSizeChanger: true,
      total: this.props.totalLinks,
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState({
      pagination: pager,
    });
    this.props.onTableChange(pagination, filters, sorter);
  };

  render() {
    const { loading, links, availableProducts, availableKinds } = this.props;
    const productFilters = availableProducts.map((product) => ({
      text: product.name,
      value: product.slug,
    }))
    const kindFilters = availableKinds.map((kind) => ({
      text: kind,
      value: kind,
    }))

    let columns = [
      {
        title: 'Product',
        dataIndex: 'product',
        render: (product) => product.name,
        filterMultiple: true,
        filters: productFilters,
      },
      {
        title: 'URL',
        dataIndex: 'url',
        render: (url) => <Paragraph style={{marginBottom: 0}} copyable>{url}</Paragraph >,
        sorter: true,
      },
      {
        title: 'Description',
        dataIndex: 'description',
      },
      {
        title: 'Click Count',
        dataIndex: 'click_count',
      },
      {
        title: 'Type',
        dataIndex: 'kind',
        render: (kind) => kind,
        filterMultiple: false,
        filters: kindFilters,
      },
    ];

    const { pagination } = this.state;
    pagination.total = this.props.totalLinks;
    pagination.current = this.props.page;

    return (
      <Table
        columns={columns}
        rowKey={record => record.id}
        dataSource={links}
        pagination={pagination}
        loading={loading}
        onChange={this.handleTableChange}
        size="middle"
        scroll={{ x: true }}
      />
    );
  }
}

List.propTypes = {
  totalLinks: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  links: PropTypes.array.isRequired,
  availableProducts: PropTypes.array.isRequired,
  availbaleKinds: PropTypes.array.isRequired,
  onTableChange: PropTypes.func.isRequired,
};

List.defaultProps = {
  totalLinks: 0,
  page: 0,
  loading: true,
  links: [],
  availableProducts: [],
  availbaleKinds: [],
};

export default List;
