import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { usePrevious } from '../../util/misc';
import { Modal, Alert, Button, message } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";
import { acceptTerm } from "../../actions/termActions";
import { fetchProfile } from '../../actions/profileActions'
import TermsCopy from './TermsCopy';

const AcceptTermsModal = (props) => {

  const onAccept = () => {
    props.dispatch(acceptTerm());
  };

  const { success } = props
  const prevSuccess = usePrevious({ success });

  useEffect(() => {
    if (prevSuccess && prevSuccess.success !== success && success) {
      message.success(`Terms accepted successfully`);
      props.dispatch(fetchProfile());
    }
  }, [success])

  let { visible, loading } = props;

  return (
    <Modal
      className="terms-modal"
      title={`Accept terms of use`}
      visible={visible}
      closable={false}
      footer={null}
      width={'80%'}
    >
      {loading ? <LoadingOutlined /> :
        <>
          
          <TermsCopy />
          <Button loading={loading} type="primary" onClick={onAccept}>
            I accept
          </Button>
        </>
      }
    </Modal>
  );

}

const mapStateToProps = state => ({
  loading: state.terms.loading,
  success: state.terms.success,
});

export default connect(mapStateToProps)(AcceptTermsModal)