import React from "react";
import moment from "moment";
import styled from "styled-components";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { fetchDashboard } from "../actions/dashboardActions";
import {
  Skeleton,
  DatePicker,
  Divider,
  Row,
  Col,
  Card,
  Statistic,
  Typography,
  Tooltip,
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

const { Title } = Typography;

const DatePickerWrapper = styled.div`
  width: 236px;
`;

const WhiteTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;

const EarningsValue = styled.div`
  margin-bottom: 20px;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px;
  letter-spacing: 0.35px;
`;

class Dashboard extends React.Component {
  state = {
    start_date: moment().add(-2, "weeks"),
    end_date: moment(),
  };

  componentDidMount() {
    const { start_date, end_date } = this.state;
    this.props.dispatch(fetchDashboard([start_date, end_date]));
  }

  fetchDashboardData = (value) => {
    this.setState({
      start_date: value[0],
      end_date: value[1],
    });
    this.props.dispatch(fetchDashboard([value[0], value[1]]));
  };

  formatValue = (value) => {
    return Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value ? value.toFixed(2) : 0);
  };

  render() {
    const { dashboard } = this.props;
    const { start_date, end_date } = this.state;

    if (!dashboard || dashboard === {}) {
      return <Skeleton />;
    }

    return (
      <>
        <Helmet>
          <title>Dashboard</title>
        </Helmet>
        <Row justify="space-between">
          <Title level={4}>Dashboard</Title>
          <DatePickerWrapper>
            <DatePicker.RangePicker
              style={{ width: "100%", marginBottom: "2em" }}
              defaultValue={[start_date, end_date]}
              ranges={{
                Today: [moment(), moment()],
                "This week": [moment().startOf("week"), moment().endOf("week")],
                "Last 2 Weeks": [moment().add(-2, "weeks"), moment()],
                "This Month": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
              }}
              onChange={this.fetchDashboardData}
            />
          </DatePickerWrapper>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Card
              style={{
                minHeight: "252px",
                textAlign: "center",
                color: "#fff",
                background: "#1C80CF",
                borderRadius: "8px",
              }}
            >
              <WhiteTitle>Lifetime earnings</WhiteTitle>
              <EarningsValue>
                {this.formatValue(dashboard.total_commission)}
              </EarningsValue>
              <Row justify="space-between">
                <Col>Total paid out</Col>
                <Col>{this.formatValue(dashboard.total_paid)}</Col>
              </Row>
              <Divider
                style={{ margin: "10px 0", borderTop: "1px solid #fff" }}
              />
              <Row justify="space-between">
                <Col>Upcoming</Col>
                <Col>{this.formatValue(dashboard.total_due)}</Col>
              </Row>
              <Divider
                style={{ margin: "10px 0", borderTop: "1px solid #fff" }}
              />
              <Row justify="space-between">
                <Col>Total refunded</Col>
                <Col>{this.formatValue(dashboard.total_failed_refunded)}</Col>
              </Row>
            </Card>
          </Col>
          <Col span={8}>
            <Row gutter={[16, 24]}>
              <Col span={24}>
                <Card style={{ borderRadius: "8px" }}>
                  <Statistic
                    title={
                      <>
                        Clicks{" "}
                        <Tooltip
                          placement="top"
                          title="The total number of times users have clicked on your affiliate links."
                        >
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </>
                    }
                    value={dashboard.clicks_count}
                  />
                </Card>
              </Col>
              <Col span={24}>
                <Card style={{ borderRadius: "8px" }}>
                  <Statistic
                    title={
                      <>
                        Unique clicks{" "}
                        <Tooltip
                          placement="top"
                          title="The number of distinct users who have clicked on your affiliate links. Multiple clicks from the same user are counted as one unique click."
                        >
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </>
                    }
                    value={dashboard.sessions_count}
                  />
                </Card>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row gutter={[16, 24]}>
              <Col span={24}>
                <Card style={{ borderRadius: "8px" }}>
                  <Statistic
                    title={
                      <>
                        Sales{" "}
                        <Tooltip
                          placement="top"
                          title="The total number of completed transactions generated through your affiliate links. Excludes refunded sales."
                        >
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </>
                    }
                    value={dashboard.conversions_count}
                  />
                </Card>
              </Col>
              <Col span={24}>
                <Card style={{ borderRadius: "8px" }}>
                  <Statistic
                    title={
                      <>
                        Earnings{" "}
                        <Tooltip
                          placement="top"
                          title="The total amount of commission you've earned from sales generated through your affiliate links."
                        >
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </>
                    }
                    value={this.formatValue(dashboard.commission)}
                    precision={2}
                  />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard.dashboard,
});

export default connect(mapStateToProps)(Dashboard);
