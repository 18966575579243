import React from "react"
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'
import { connect } from 'react-redux';

import { fetchProfile } from '../actions/profileActions'

import PrivateRoute from './shared/PrivateRoute'

import Login from './Login'
import Dashboard from './Dashboard'
import ScrollToTop from "./ScrollToTop";
import Account from './Account'
import Links from './Links'
import Marketing from './Marketing'
import Conversions from './Conversions'
import Subscriptions from './Subscriptions'
import Payouts from './Payouts'
import SystemAccess from "./account/SystemAccess";
import PayoutInformation from "./account/PayoutInformation";
import AcceptTermsModal from './shared/AcceptTermsModal';
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";


class Routes extends React.Component {

  componentDidMount() {
    const user = localStorage.getItem('user');
    if (user) {
      this.props.dispatch(fetchProfile());
    }
  }

  render() {
    const { profile } = this.props;

    return (
      <Router basename="/">
        <ScrollToTop />
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/sign-up" component={Register} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <PrivateRoute exact path="/" component={Dashboard} user={profile} />
          <PrivateRoute exact path="/account" component={Account} user={profile} />
          <PrivateRoute exact path="/links" component={Links} user={profile} />
          <PrivateRoute exact path="/payouts" component={Payouts} user={profile} />
          <PrivateRoute exact path="/conversions" component={Conversions} user={profile} />
          <PrivateRoute exact path="/subscriptions" component={Subscriptions} user={profile} />
          <PrivateRoute exact path="/marketing" component={Marketing} user={profile} />
          <PrivateRoute exact path="/account/system_access" component={SystemAccess} user={profile} />
          <PrivateRoute exact path="/account/payout" component={PayoutInformation} user={profile} />
        </Switch>
        {profile &&
          <AcceptTermsModal visible={profile.requires_terms_acceptance} />
        }
      </Router>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.profile.profile,
});
export default connect(mapStateToProps)(Routes);
