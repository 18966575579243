import React from "react"
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { BASE_CONVERSION_ROUTES } from '../util/routes';

import PageHeader from './shared/PageHeader';
import List from './conversions/List';
import { fetchConversions } from "../actions/conversionActions";

const TITLE = 'Transactions'

class Conversions extends React.Component {
  state = {
    page: 1,
    pageSize: 10,
    filters: {},
    sorter: {},
    query: '',
  };

  componentDidMount() {
    this.props.dispatch(fetchConversions());
  }

  handleTableChange = (pagination, filters, sorter) => {
    const page = pagination.current;
    const pageSize = pagination.pageSize;
    let { query } = this.state;
    this.props.dispatch(fetchConversions(page, pageSize, filters, sorter, query));
    this.setState({
      page, filters, sorter, pageSize
    });
  };

  render() {
    const { loading, conversions, availableProducts, totalItems } = this.props;
    const { page } = this.state;

    return (
      <>
        <PageHeader title={TITLE} routes={BASE_CONVERSION_ROUTES} />
        <div className="content-holder" style={{ background: '#fff' }}>
          <List conversions={conversions} availableProducts={availableProducts} totalConversions={totalItems} onTableChange={this.handleTableChange} loading={loading} page={page} />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  conversions: state.conversions.items,
  availableProducts: state.conversions.availableProducts,
  totalItems: state.conversions.totalItems,
  loading: state.conversions.loading,
});

export default connect(mapStateToProps)(withRouter(Conversions));