import _ from 'lodash';
import React from "react"
import { connect } from "react-redux";
import { List, Card, Button, Skeleton, Input, Select, Image, Tag } from 'antd';
import { fetchMarketingResources, fetchMarketingResourceUrl } from "../../actions/marketingResourceActions";
import { CloudDownloadOutlined, SearchOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { FaFilePdf, FaImage, FaGoogleDrive } from 'react-icons/fa';
import { AiOutlineFilePdf } from 'react-icons/ai';

const typeToIconMapping = {
  'banner': <FaImage />,
  'document': <FaFilePdf />,
  'google_drive': <FaGoogleDrive />
}

class MarketingResource extends React.Component {

  state = {
    page: 1,
    query: '',
    type: '',
    product: '',
    pagination: {
      current: 1,
      total: this.props.totalItems,
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
    }
  };

  handlePageChange = (page) => {
    let { query, type, product } = this.state;
    this.props.dispatch(fetchMarketingResources(page, query, type, product));
    this.setState({
      page
    });
  };

  downloadResource = async (item) => {
    let url;
    url = await fetchMarketingResourceUrl(item.id);
    window.open(url);
  };

  componentDidMount() {
    this.props.dispatch(fetchMarketingResources());
  }

  handleTypeChange = type => {
    let { query, product } = this.state;
    this.props.dispatch(fetchMarketingResources(1, query, type, product));
    this.setState({ type })
  }

  handleProductChange = product => {
    let { query, type } = this.state;
    this.props.dispatch(fetchMarketingResources(1, query, type, product));
    this.setState({ product })
  }

  handleSearch = _.debounce((query) => {
    const { type, product } = this.state;
    this.props.dispatch(fetchMarketingResources(1, query, type, product));
    this.setState({ query })
  }, 300);

  render() {
    const { marketingResources, totalItems, mobileView, products } = this.props;
    if (totalItems === undefined) {
      return (
        <Skeleton />
      )
    }
    const { page, type, product } = this.state;
    let pagination = {
      current: page,
      onChange: this.handlePageChange,
      pageSize: 12,
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
    }
    pagination.total = this.props.totalItems;
    return (
      <>
        <div className="case-studies-header" style={{ padding: '10px', background: '#fff', marginBottom: '1em' }}>
          <div className="button-action-bar">
            <Input
              placeholder="Search by title or tag" onChange={(e) => this.handleSearch(e.target.value)}
              suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
            <span style={{ width: '60px', marginLeft: `${!mobileView && '3em'}`, marginTop: 'auto', marginBottom: 'auto' }}>{!mobileView && 'View:'}</span>
            <Select onChange={this.handleTypeChange} style={{ width: '230px', marginLeft: '.5em' }} value={type}>
              <Select.Option value="">All types</Select.Option>
              <Select.Option value="banner">Banner</Select.Option>
              <Select.Option value="document">Document</Select.Option>
              <Select.Option value="google_drive">Google Drive</Select.Option>
            </Select>
            <span style={{ width: '60px', marginLeft: `${!mobileView && '1em'}`, marginTop: 'auto', marginBottom: 'auto' }}>{!mobileView && 'Product:'}</span>
            <Select onChange={this.handleProductChange} style={{ width: '230px', marginLeft: '.5em' }} value={product}>
              <Select.Option value="">All products</Select.Option>
              { products.map(p => <Select.Option key={p.id} value={p.id}>{p.name}</Select.Option>)}
            </Select>
          </div>
        </div>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 3,
            xxl: 4,
          }}
          dataSource={marketingResources}
          pagination={pagination}
          className='case-studies-list'
          renderItem={item => {
            const action = item.kind === 'google_drive' ?
            <Button icon={<ArrowRightOutlined />} type="link" href={item.remote_file_url} target="_blank">Open</Button>
            :
            <Button icon={<CloudDownloadOutlined />} type="link" onClick={() => this.downloadResource(item)}>Download</Button>
            return (
              <List.Item>
                <Card
                  extra={
                    <>
                      { item.product && <Tag style={{marginRight: '1em'}} color='blue'>{item.product.name}</Tag> }
                      {typeToIconMapping[item.kind]}
                    </>
                  }
                  actions={[action]}
                  title={item.title}>
                  <div style={{ textAlign: 'center' }}>
                    {item.file_type && item.file_type.split('/')[0] === 'image' ?
                      <Image src={item.file_url} height={150} />
                      : <AiOutlineFilePdf fontSize={144} />
                    }
                  </div>
                </Card>
              </List.Item>
            )}
          }
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  marketingResources: state.marketingResources.items,
  totalItems: state.marketingResources.totalItems,
  error: state.marketingResources.error,
  tags: state.marketingResources.tags,
  products: state.marketingResources.products
});

export default connect(mapStateToProps)(MarketingResource);

