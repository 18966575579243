import React from "react";
import { Form, Input, Button, Card, Alert, Spin, Row, Col, Typography } from 'antd';
import { connect } from 'react-redux';
import logo from '../images/logo_large.png'
import { createAffiliate, createAffiliateClear } from "../actions/affiliateActions";
import SuccessDisplay from "./shared/SuccessDisplay";
import NestedAddressForm from "./shared/NestedAddressForm";

const { Paragraph } = Typography;

class Register extends React.Component {

  handleSubmit = values => {
    this.props.dispatch(createAffiliate(values));
  };

  render() {
    const { error, loading, success } = this.props;
    if (success) {
      this.props.dispatch(createAffiliateClear());
      return <SuccessDisplay />
    }
    else {
      return (
        <div className="login-container">
          <Card style={{ width: 700, maxWidth: 900 }}>
            {error ? <Alert message={error && error.error} type="error" style={{ marginBottom: '1em' }} /> : ''}
            <Form layout="vertical" name="nest-messages" onFinish={this.handleSubmit}>
              <img src={logo} style={{ marginBottom: '2em', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} alt="Umbrella" />
              <Paragraph>
                Fill in the information below to become an Umbrella affiliate.
              </Paragraph>
              <Row gutter={16}>
                <Col sm={12} xs={12}>
                  <Form.Item name="first_name" rules={[{ required: true, message: 'Please enter your First Name' }]}>
                    <Input placeholder="First Name" />
                  </Form.Item>
                </Col>
                <Col sm={12} xs={12}>
                  <Form.Item name="last_name" rules={[{ required: true, message: 'Please enter your Last Name' }]}>
                    <Input placeholder="Last Name" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col sm={12} xs={12}>
                  <Form.Item name="email" rules={[{ type: 'email', message: 'Please enter valid E-mail!' }, { required: true, message: 'Please enter your E-mail!' }]}>
                    <Input placeholder='Email' />
                  </Form.Item>
                </Col>
                <Col sm={12} xs={12}>
                  <Form.Item name="phone" rules={[{ required: true, message: 'Please enter your phone number!' },]}>
                    <Input placeholder="Phone Number" style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col sm={12} xs={12}>
                  <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!', },]} hasFeedback>
                    <Input.Password placeholder="Password" />
                  </Form.Item>
                </Col>
                <Col sm={12} xs={12}>
                  <Form.Item name="confirm" dependencies={['password']} hasFeedback
                    rules={[{ required: true, message: 'Please confirm your password!' },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject('The two passwords that you entered do not match!');
                      },
                    }),
                    ]}
                  >
                    <Input.Password placeholder="Confirm Password" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col sm={12} xs={12}>
                  <Form.Item name="company" rules={[{ required: true, message: 'Please enter your Company' }]}>
                    <Input placeholder="Company" />
                  </Form.Item>
                </Col>
                <Col sm={12} xs={12}>
                  <Form.Item name="website" rules={[{ required: true, message: 'Please enter website!' }]} >
                    <Input placeholder="Website" />
                  </Form.Item>
                </Col>
              </Row>

              <NestedAddressForm />

              <Button block type="primary" htmlType="submit" className="login-form-button" disabled={loading}>
                Sign Up
              </Button>
              {loading ? <div style={{ textAlign: 'center' }}><Spin /></div> : ''}
              <div></div>
              <div>
                <a style={{ float: 'left' }} href="/login">
                  Back to Login
                </a>
              </div>
            </Form>
          </Card>
        </div>
      );
    }
  }
}


const mapStateToProps = state => ({
  loading: state.affiliates.loading,
  error: state.affiliates.error,
  success: state.affiliates.success,
  affiliate: state.affiliates.affiliate
});
export default connect(mapStateToProps)(Register);