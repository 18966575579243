import {
  FETCH_MARKETING_RESOURCES_BEGIN,
  FETCH_MARKETING_RESOURCES_SUCCESS,
  FETCH_MARKETING_RESOURCES_FAILURE,
} from '../actions/marketingResourceActions';

const initialState = {
  items: [],
  loading: false,
  error: null,
  tags: [],
  products: []
};

export default function marketingResourceReducer(state = initialState, action) {
  switch(action.type) {
    case FETCH_MARKETING_RESOURCES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_MARKETING_RESOURCES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.resources,
        totalItems: action.payload.totalItems,
        tags: action.payload.popularTags,
        products: action.payload.products,
      };

    case FETCH_MARKETING_RESOURCES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: []
      };

    default:
      return state;
  }
}