import React from "react";
import { Result, Button } from 'antd';
import { withRouter } from "react-router-dom";


const SuccessDisplay = withRouter(({ history }) => (

  <Result
    status="success"
    title="Thank you!"
    subTitle="Your request has been submitted and we will follow up shortly."
    extra={[
      <Button type="primary" onClick={() => { history.push('/login') }} >
        Back to Login
      </Button>
    ]}
  />
)
)
export default SuccessDisplay;

