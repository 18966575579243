import { buildSortString, buildFilterString } from "../util/api";
import axios from "axios";
import { authHeader } from "../util/api";

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export const FETCH_SUBSCRIPTIONS_BEGIN = "FETCH_SUBSCRIPTIONS_BEGIN";
export const FETCH_SUBSCRIPTIONS_SUCCESS = "FETCH_SUBSCRIPTIONS_SUCCESS";
export const FETCH_SUBSCRIPTIONS_FAILURE = "FETCH_SUBSCRIPTIONS_FAILURE";

export const fetchSubscriptionsBegin = () => ({
  type: FETCH_SUBSCRIPTIONS_BEGIN,
});

export const fetchSubscriptionsSuccess = (
  subscriptions,
  availableProducts,
  totalItems
) => ({
  type: FETCH_SUBSCRIPTIONS_SUCCESS,
  payload: { subscriptions, availableProducts, totalItems },
});

export const fetchSubscriptionsFailure = (error) => ({
  type: FETCH_SUBSCRIPTIONS_FAILURE,
  payload: { error },
});

export function fetchSubscriptions(page = 1, pageSize = 10, filters, sorter, query = '') {
  return (dispatch) => {
    dispatch(fetchSubscriptionsBegin());
    let sortString = buildSortString(sorter);
    let filterString = buildFilterString(filters);

    let queryString = "";
    if (query !== undefined && query !== "") {
      queryString = `&q[user_first_name_or_user_last_name_or_user_email_cont]=${query}`;
    }

    return axios
      .get(
        `${BASE_API_URL}/subscriptions.json?page=${page}&per_page=${pageSize}${sortString}${filterString}${queryString}`,
        { headers: authHeader() }
      )
      .then((json) => {
        dispatch(
          fetchSubscriptionsSuccess(
            json.data.subscriptions,
            json.data.available_products,
            json.data.total_entries
          )
        );
        return json.data.subscriptions;
      })
      .catch((error) => dispatch(fetchSubscriptionsFailure(error)));
  };
}
