import React from "react"

import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { Form, Row, Col, Input } from 'antd';

class AddressForm extends React.Component {
  constructor(props) {
    super(props);
    let { address } = this.props;
    if (address === undefined) {
      this.state = { country: '', state: '' };
    } else {
      this.state = { country: address.country, state: address.state };
    }
  }

  selectCountry(val) {
    this.setState({ country: val });
  }

  selectRegion(val) {
    this.setState({ region: val });
  }

  render() {
    const { country } = this.state;
    let { address } = this.props;
    const required = this.props.required === true;
    if (address === undefined) {
      address = {}
    }
    return (
      <>
        <Row gutter={16}>
          <Col sm={16} xs={24}>
            <Form.Item label="Street Address" name="address1" rules={[{ required: required, message: 'Please enter a street address' }]}>
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col sm={8} xs={24}>
            <Form.Item label="Line 2" name="address2">
              <Input placeholder="" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={16} xs={24}>
            <Form.Item label="City" name="city" rules={[{ required: required, message: 'Please enter a city' }]}>
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col sm={8} xs={24}>
            <Form.Item label="Postal Code" name="postal_code" rules={[{ required: required, message: 'Please enter a postal code' }]}>
              <Input placeholder="" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={12} xs={24}>
            <Form.Item label="Country" name="country" rules={[{ required: required, message: 'Please enter a country' }]}>
              <CountryDropdown
                classes={'ant-select'}
                valueType='short'
                onChange={(val) => this.selectCountry(val)} />
            </Form.Item>
          </Col>
          <Col sm={12} xs={24}>
            <Form.Item label="Region / Province" name="region" rules={[{ required: required, message: 'Please select a region' }]}>
              <RegionDropdown
                country={country} countryValueType={'short'}
                valueType='short'
                classes={'ant-select'}
                onChange={(val) => this.selectRegion(val)} />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
}

export default AddressForm