import _ from 'lodash';
import React from "react"
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { BASE_LINK_ROUTES } from '../util/routes';

import PageHeader from './shared/PageHeader';
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import List from './links/List'
import { fetchLinks } from "../actions/linkActions";

const TITLE = 'Links'

class Links extends React.Component {
  state = {
    page: 1,
    pageSize: 10,
    filters: {},
    sorter: {},
    query: '',
  };

  componentDidMount() {
    this.props.dispatch(fetchLinks());
  }

  handleTableChange = (pagination, filters, sorter) => {
    const page = pagination.current;
    const pageSize = pagination.pageSize;
    let { query } = this.state;
    this.props.dispatch(fetchLinks(page, pageSize, filters, sorter, query));
    this.setState({
      page, filters, sorter, pageSize
    });
  };

  handleSearch = _.debounce((query) => {
    let { filters, sorter, pageSize } = this.state;
    this.props.dispatch(fetchLinks(1, pageSize, filters, sorter, query));
  }, 300);

  render() {
    const { loading, links, availableProducts, availableKinds, totalItems } = this.props;
    const { page } = this.state;

    return (
      <>
        <PageHeader title={TITLE} routes={BASE_LINK_ROUTES} />
        <div className="content-holder" style={{ background: '#fff' }}>
          <Input
            placeholder="Search by name, email, phone or company" onChange={(e) => this.handleSearch(e.target.value)}
            suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          />
          <List links={links} availableProducts={availableProducts} availableKinds={availableKinds} totalLinks={totalItems} onTableChange={this.handleTableChange} loading={loading} page={page} />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  links: state.links.items,
  availableProducts: state.links.availableProducts,
  availableKinds: state.links.availableKinds,
  totalItems: state.links.totalItems,
  loading: state.links.loading,
  error: state.links.error
});

export default connect(mapStateToProps)(withRouter(Links));
