import React from 'react';
import { Typography, Alert } from 'antd';

const { Title, Paragraph, Text } = Typography;

const TermsCopy = () => (
  <Typography>
    <Alert
      type="warning"
      style={{ marginBottom: '2em' }}
      description="Before proceeding, you must read the following Terms of Use and click 'I accept' at the bottom of the page." />

    <Title level={3} style={{ textAlign: "center" }}>AFFILIATE MASTER AGREEMENT</Title>
    <Paragraph>
      By clicking in the ACCEPT TERMS button you, the Affiliate, accept the terms and conditions of this Affiliate Master Agreement.
    </Paragraph>
    <Paragraph>
      This Affiliate Master Agreement is entered into by and between Umbrella Micro Enterprises Inc., having its principal place of business at 1330 6th Ave. New York, NY, 10019 (the <Text strong>"Company"</Text>), and you the Affiliate (<Text strong>"Affiliate"</Text>). The Company and Affiliate are each a <Text strong>"Party"</Text> and collectively, the <Text strong>"Parties"</Text>.
    </Paragraph>
    <Paragraph>
      In consideration of the foregoing premises and agreements hereinafter contained, the Parties mutually agree as follows:
      <ol>
        <li>
          <dt>Definitions</dt>
          <dd>
            <ul style={{ listStyleType: 'disc' }}>
              <li>
                "Confidential Information" means any information disclosed by or on behalf of the Disclosing Party to the Recipient before or during the term of this Agreement, except information that Recipient can demonstrate by written evidence that: (a) is or becomes generally available to the public other than as a result of disclosure by the Recipient; (b) is already known by or in the possession of the Recipient at the time of disclosure by the Disclosing Party as evidenced by written documentation in the Recipient’s possession prior to receipt of the Confidential Information;  (c) is independently developed by the Recipient without use of or reference to the Confidential Information; or (d) is obtained by the Recipient from a third party that has not breached any obligations of confidentiality.
              </li>
              <li>
                "Customer" means an entity that acquires a license to or purchases the Product for its own internal personal use, not for resale or redistribution to others.
              </li>
              <li>
                "Disclosing Party" means the Party disclosing Confidential Information hereunder.
              </li>
              <li>
                "Documentation" means any marketing, brochures or other explanatory user materials which may be supplied by the Company to Affiliate with respect to the Products in paper and/or electronic form.
              </li>
              <li>
                "Product(s)" means the Company’s proprietary – as provided in the Affiliate Dashboard - to be promoted by Affiliate in the Territory in accordance with the terms and conditions hereunder.
              </li>
              <li>
                "Affiliate Dashboard" – The Company will provide Affiliate with this online dashboard via which the Affiliate will have access to links and documentations to promote the specific Products assigned to the Affiliate.
              </li>
              <li>
                "Prospective Customer" means an entity to which Affiliate intends to market, resell or license the Products.
              </li>
              <li>
                "Recipient" means the Party receiving Confidential Information hereunder.
              </li>
              <li>
                "Territory" means – as set forth in Insertion Order.
              </li>
              <li>
                "Gross Sales" means – total sales (top line sales).
              </li>
              <li>
                "Net Revenue" means – Gross Sales minus processing fees and sales taxes/VAT if applicable.
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dt>Appointment and Obligations</dt>
          <dd>
            <ul style={{ listStyleType: 'disc' }}>
              <li>
                <dt>Appointment</dt>
                <dd>
                  Subject to the terms and conditions of this Agreement, the Company appoints Affiliate, on a non-exclusive, non-transferable basis, to promote the Products to Customers in the Territory during the Term (as defined below). Affiliate may not enter into agreements with Customers on any terms that shall conflict, exceed or be inconsistent with the terms and conditions hereunder. Notwithstanding anything to the contrary hereunder, it is hereby clarified that the term Affiliate under this Agreement is used solely to specify a collaborative business relationship between the Parties and should not be understood to refer to any control, directly or indirectly, of the Company or by the Company in the Affiliate.
                </dd>
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dt>License Grants</dt>
          <dd>
            <ul style={{ listStyleType: 'disc' }}>
              <li>
                Subject to the terms and conditions hereunder, the Company grants to Affiliate a limited, non-exclusive, sub-licensable, non-transferable license to use trade names, service marks and the trademarks of the Company (collectively, the <Text strong>"Company Marks"</Text>) for advertising and promotion of the Products within the Territory during the Term. Affiliate may not modify or alter the Company Marks in any manner without the prior written approval of the Company.  Affiliate agrees to display the Company Marks on all the Company related materials distributed by Affiliate. Use by Affiliate of the Company Marks shall, at all times, inure to the benefit of Company and be conditioned upon compliance with the Company’s most current guidelines for use of the Company Marks as were provided to Affiliate in writing by the Company.
              </li>
              <li>
                Affiliate undertakes not to: (i) copy, display, transfer, adapt, modify, distribute or reproduce, in any manner, and whether in tangible or intangible form, the Products, or any part or component of it, except as permitted under this Agreement; (ii) decompile, decrypt, reverse engineer, disassemble or otherwise determine, attempt to determine or reduce the Products to human-readable form, if applicable; (iii) modify or create derivative works of any portion of the Products; (iv) except as otherwise provided hereunder, sublease, sublicense, transfer or assign any part of the Products; (v) provide any licenses or rights to the Products which are inconsistent with the terms and conditions of this Agreement; or (vi) use the Products in such a manner which would constitute a breach of applicable laws or regulations.
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dt>Marketing</dt>
          <dd>
            <ul style={{ listStyleType: 'disc' }}>
              <li>
                The Company shall provide to Affiliate with Marketing Materials to promote the Products and its (the <Text strong>"Marketing Materials"</Text>) which will be available in the Affiliate Dashboard. Affiliate may use the Marketing Materials solely for purpose of promoting the Products. Affiliate may not make alteration in the Marketing Material that may present a different meaning than originally attended and may not alter Company graphic branding materials such as Logos and Banners.
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dt>Ownership</dt>
          <dd>
            <ul style={{ listStyleType: 'disc' }}>
              <li>
                Except for the licenses and rights granted hereunder, the Company owns all right, title and interest in and to the Products, Documentation, Company Marks and Marketing Materials furnished to Affiliate, and nothing in this Agreement shall restrict, impair, transfer, license, convey or otherwise alter or deprive the Company of any of its rights or proprietary interests in any of its intellectual property. It is hereby clarified that any feedback, ideas, suggestions made the Affiliate or Customers with respect to the Products (<Text strong>"Feedback"</Text>) shall be the exclusive property of the Company, and the Company shall be free to use the Feedback at its sole discretion.
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dt>Obligations of Affiliate</dt>
          <dd>
            <ul style={{ listStyleType: 'disc' }}>
              <li>
                Affiliate will (a) use reasonable commercial efforts to market, promote and solicit the sale of licenses to the Products to Prospective Customers in the Territory, (b) promote the goodwill, name and reputation of the Company and of the Products, and refrain from committing any acts or omissions which might reflect unfavorably on the Company, (c) represent the Products accurately and fairly and at all times avoid misleading or unethical business practices. Affiliate shall not, nor shall Affiliate permit any Customer to, reverse engineer, decrypt, disassemble, disseminate or reverse compile the Products.
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dt>Audit</dt>
          <dd>
            <ul style={{ listStyleType: 'disc' }}>
              <li>
                The Company and its representatives shall have the right, at its own expense, to conduct an audit of the relevant books, records and accounts of the Affiliate related to the sub-license of the Products and/or its obligations under this Agreement, during normal business hours upon giving reasonable prior notice of their intent to conduct such an audit. In the event of such audit, the Affiliate shall comply with the reasonable requests of the Company and its representatives and provide access to all books, records and accounts necessary to the audit.
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dt>Pricing and Payment</dt>
          <dd>
            <ul style={{ listStyleType: 'disc' }}>
              <li>
                <dt>Prices</dt>
                <dd>
                  Company shall pay the Affiliate for the Products as detailed in this section and in accordance with the terms and conditions hereunder (the <Text strong>"Commissions"</Text>).
                  <ul style={{ listStyleType: 'disc' }}>
                    <li>
                      For up to $8,000 per month in Gross Sales generated by the Affiliate, Affiliate will be entitled to 30% commission of the Net Revenue generated by the Affiliate.
                    </li>
                    <li>
                      For up to $12,000 per month in Gross Sales generated by the Affiliate, Affiliate will be entitled to 40% commission of the Net Revenue generated by the Affiliate.
                    </li>
                    <li>
                      Above $12,000 per month in Gross Sales generated by the Affiliate, Affiliate will be entitled to 30% commission of the Net Revenue generated by the Affiliate.
                    </li>
                  </ul>
                </dd>
              </li>
              <li>
                <dt>Taxes</dt>
                <dd>
                  Any taxes which Affiliate is legally required to pay in connection with any license or resale hereunder will be the sole responsibility of the Affiliate. The Affiliate shall be solely responsible for the payment of taxes based on its net income.
                </dd>
              </li>
              <li>
                <dt>Payment</dt>
                <dd>
                  Company shall pay all invoices issued to it by the Affiliate within 30 days as of date of such invoice. All payments due under this Agreement shall be paid in accordance with the details set forth under Exhibit A, including, among others, with respect to the method of payment.
                </dd>
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dt>Warranties and Indemnification</dt>
          <dd>
            <ul style={{ listStyleType: 'disc' }}>
              <li>
                <dt>General Warranties</dt>
                <dd>
                  Each Party represents and warrants that: (a) it has validly entered into this Agreement and has the power and authority to do so; (b) the provisions set forth in this Agreement constitute legal, valid, and binding obligations of such Party enforceable against such Party; (c) its execution, delivery and performance of this Agreement does not and will not conflict with, result in a breach of, constitute a default under, or require the consent of any third party; and (d) each Party shall comply with all laws, rules, regulations and ordinances applicable to its activities hereunder.
                </dd>
              </li>
              <li>
                <dt>EXCLUSION OF WARRANTIES</dt>
                <dd>
                  EXCEPT FOR THE WARRANTIES GIVEN BY THE COMPANY IN THIS AGREEMENT, THE COMPANY MAKES NO OTHER WARRANTIES, EXPRESS OR IMPLIED. THE COMPANY HEREBY SPECIFICALLY DISCLAIMS ALL OTHER WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR A PARTICULAR PURPOSE. THE EXCLUSIONS SET FORTH IN THIS SECTION SHALL APPLY TO THE EXTENT PERMITTED UNDER APPLICABLE LAW.
                </dd>
              </li>
              <li>
                <dt>LIMITATION OF LIABILITY</dt>
                <dd>
                  NEITHER THE COMPANY NOR AFFILIATE SHALL BE LIABLE TO THE OTHER PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING IN CONNECTION WITH THIS AGREEMENT, WHETHER IN AN ACTION IN CONTRACT, TORT, STRICT LIABILITY OR NEGLIGENCE, OR OTHER ACTIONS, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. EXCEPT FOR BREACH OF CONFIDENTIALITY OBLIGATIONS, WILLFUL MISCONDUCT, FRAUD OR BREACH OF SECTION ‎3.3 AND ‎9.4 HEREUNDER, NEITHER PARTY’S AGGREGATE LIABILITY FOR DAMAGES HEREUNDER, REGARDLESS OF THE FORM OF ACTION, SHALL EXCEED THE TOTAL AMOUNTS PAID UNDER THIS AGREEMENT IN THE PRECEDING 12 MONTHS PRIOR TO THE CLAIM GIVING RISE TO THE LIABILITY. THE LIMITATIONS OF LIABILITY SET FORTH IN THIS SECTION SHALL APPLY TO THE EXTENT PERMITTED UNDER APPLICABLE LAW.
                </dd>
              </li>
              <li>
                <dt>General Indemnity</dt>
                <dd>
                  Affiliate agrees, at its expense, to defend, indemnify and hold harmless the Company from and against all liabilities, damages, costs, Commissions and expenses, including reasonable attorneys’ Commissions and experts’ Commissions, arising from suits, claims, actions or proceedings brought by any third party on account of (a) personal injury, death or physical property damage caused by the negligence or willful misconduct of Affiliate, its agents, representatives or employees in the course of performing Affiliate’s obligations under this Agreement or in breach thereof, or (b) any breach of this Agreement by Affiliate.
                </dd>
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dt>Confidential Information</dt>
          <dd>
            <ul style={{ listStyleType: 'disc' }}>
              <li>
                The Recipient shall use the Confidential Information only for the purpose of meeting its obligations or exercising its rights under this Agreement. The Recipient shall not disclose or otherwise make available any of the Confidential Information to anyone, including employees, contractors and representatives, except those employees, contractors and representatives of the Recipient and entities controlled by, controlling or under common control with the Recipient who need to know the Confidential Information for the purpose of meeting Recipient’s obligations under this Agreement or exercising its rights under this Agreement, and who are bound by obligations of non-use and non-disclosure substantially similar to those set forth herein. The Recipient shall be responsible for any use or disclosure of the Confidential Information by such employees, contractors, representatives or commonly controlled entities.
              </li>
              <li>
                The Recipient shall use commercially reasonable efforts (but in any event not less than those employed for safeguarding its own proprietary information) to keep the Confidential Information and/or any knowledge which may be imparted through examination thereof or working therewith confidential.
                </li>
              <li>
                <dt>Compelled Disclosure</dt>
                <dd>
                  The Recipient may disclose the Confidential Information to the extent that such disclosure is required by law or court order, provided that the Recipient shall promptly provide to the Disclosing Party written notice prior to such disclosure and shall provide reasonable assistance in obtaining an order or other remedy protecting the Confidential Information from public disclosure.
                </dd>
              </li>
              <li>
                <dt>Return of Confidential Information</dt>
                <dd>
                  Each Party shall, upon termination or expiration of this Agreement or at any time upon demand by the other Party, promptly return to the other Party all Confidential Information together with any copies or reproductions thereof and destroy all related data in its computer and other electronic files. Upon the return, or destruction, of Confidential Information the Recipient will erase all copies of the Disclosing Party’s Confidential Information from all forms of magnetic and electronic media using a method that ensures that it cannot be recovered.
                </dd>
              </li>
              <li>
                <dt>Injunctive Relief</dt>
                <dd>
                  Each Party acknowledges that disclosure of the other Party’s Confidential Information by it or breach of the provisions contained herein may give rise to irreparable injury to the other Party and such breach or disclosure may be inadequately compensable in money damages. Accordingly, each Party may seek injunctive relief against the breach or threatened breach of the foregoing undertakings.  Such remedy will not be deemed to be the exclusive remedy for any such breach but will be in addition to all other remedies available at law or equity.
                </dd>
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dt>Term and Termination</dt>
          <dd>
            <ul style={{ listStyleType: 'disc' }}>
              <li>
                <dt>Term</dt>
                <dd>
                  Unless sooner terminated as set forth hereunder, the term of this Agreement will be for an initial period of 1 year from the Effective Date. The Agreement will automatically renew for succeeding 1-year terms terminated as detailed below (collectively the <Text strong>"Term"</Text>).
                </dd>
              </li>
              <li>
                <dt>Termination for Convenience</dt>
                <dd>
                  Either Party may terminate this Agreement for convenience and without cause at any time by providing 30 days’ prior written notice to the other Party.
                </dd>
              </li>
              <li>
                <dt>Termination for Cause</dt>
                <dd>
                  Either Party may terminate this Agreement if the other Party materially breaches this Agreement in a manner which can be cured, and such breach remains uncured for 15 days following written notice of breach by the terminating Party.
                </dd>
              </li>
              <li>
                <dt>Effect of Termination or Expiration</dt>
                <dd>
                  In the event of the termination or expiration of this Agreement:
                  <ul style={{ listStyleType: 'disc' }}>
                    <li>
                      Affiliate will cease marketing and licensing the Products to Prospective Customers or Customers. Furthermore, Affiliate will promptly return to the Company or, at the Company’s sole option, destroy any Marketing Materials and other materials furnished to it by the Company, including Confidential Information, and all licenses and rights granted hereunder to Affiliate shall terminate immediately, unless explicitly permitted otherwise hereunder to survive termination.
                    </li>
                    <li>
                      Neither Party will be liable to the other for any damage, expenditures, loss of profits or prospective profits of any kind or nature sustained or arising out of, or alleged to have arisen out of such termination or expiration. Termination or expiration of this Agreement will not relieve or release either Party from making payments which may be owing to the other Party under the terms of this Agreement, including but not limited to Commissions incurred prior to the termination date.
                    </li>
                  </ul>
                </dd>
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dt>General Provisions</dt>
          <dd>
            <ul style={{ listStyleType: 'disc' }}>
              <li>
                <dt>Notices</dt>
                <dd>
                  Any notice which may be or is required to be given under this Agreement will be in writing unless otherwise specified.  All written notices shall be sent by registered or certified mail, postage prepaid, return receipt requested, or by a national commercial express courier service that provides for tracking and delivery certification.  Notices will be deemed to have been given when received, properly addressed as set forth hereinabove. Either Party may change its contact information by giving notice to the other Party pursuant to this Section.
                </dd>
              </li>
              <li>
                <dt>Assignment</dt>
                <dd>
                  Neither Party may assign this Agreement or any of its rights and obligations hereunder to any other party without the express written permission of the other Party. Notwithstanding the foregoing, the Company may assign this Agreement, without the consent of Affiliate, to an acquirer or successor in the event of a merger, reorganization, sale or acquisition or all or substantially all of its assets or business related hereto.
                </dd>
              </li>
              <li>
                <dt>Severability</dt>
                <dd>
                  If any provision of this Agreement is held invalid by any law, rule, order or regulation of any government or by the final determination of any court of competent jurisdiction such invalidity will not affect the enforceability of any other provisions not held to be invalid.
                </dd>
              </li>
              <li>
                <dt>Governing Law and Jurisdiction</dt>
                <dd>
                  This Agreement will be governed by and interpreted and construed in accordance with the laws of the Isle of Man without giving effect to its conflict of law principles. Any dispute between the Parties arising out of or in connection with this Agreement, including any question regarding its breach and/or its implementation and/or its termination, shall be referred to and settled exclusively by the competent courts in the state of New York, USA.
                </dd>
              </li>
              <li>
                <dt>Relationship of the Parties</dt>
                <dd>
                  Affiliate is an independent contractor, and nothing set forth herein shall be deemed or construed to render the parties joint venturers, partners or employer and employee. Neither Party is authorized to make any commitment or representation on the other’s behalf. During the Term if the term "partnership", "partner", "Affiliate" or "development partner" or the like is used to describe the Parties’ relationship, the Company and Affiliate agree to make it clear to third parties that these terms refer only to the spirit of cooperation between them and neither describe, nor expressly or impliedly create, the legal status of partners, corporate affiliates or joint venturers.
                </dd>
              </li>
              <li>
                <dt>Survival</dt>
                <dd>
                  Upon termination of the Agreement, the terms and/or obligations which by their nature are intended to survive termination of the Agreement shall survive, including, without limitations, the following Sections ‎‎3.2, ‎5, ‎7, and ‎8 - ‎12.
                </dd>
              </li>
              <li>
                <dt>Integration</dt>
                <dd>
                  This Agreement, including all Exhibits attached hereto, constitutes the entire agreement between the Parties in connection with the subject matter hereof, and supersedes all prior agreements, understandings, negotiations and discussions, whether oral or written, between the Parties.  In the event of a conflict between the terms of this Agreement and any Exhibit hereto, this Agreement shall take precedence.  No amendment to or modification of this Agreement will be binding unless it is in writing and signed by a duly authorized representative of each of the Parties.
                </dd>
              </li>
            </ul>
          </dd>
        </li>
      </ol>
    </Paragraph>
  </Typography>
)

export default TermsCopy