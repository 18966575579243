import _ from 'lodash';
import axios from 'axios';

const BASE_API_URL = process.env.REACT_APP_BASE_LOGIN_URL;

export const CREATE_AFFILIATE_BEGIN = 'CREATE_AFFILIATE_BEGIN';
export const CREATE_AFFILIATE_SUCCESS = 'CREATE_AFFILIATE_SUCCESS';
export const CREATE_AFFILIATE_FAILURE = 'CREATE_AFFILIATE_FAILURE';
export const CREATE_AFFILIATE_CLEAR = 'CREATE_AFFILIATE_CLEAR';

export const createAffiliateBegin = () => ({
  type: CREATE_AFFILIATE_BEGIN
});

export const createAffiliateSuccess = (affiliate) => ({
  type: CREATE_AFFILIATE_SUCCESS,
  payload: { affiliate }
});

export const createAffiliateFailure = error => ({
  type: CREATE_AFFILIATE_FAILURE,
  payload: { error }
});

export const createAffiliateClear = () => ({
  type: CREATE_AFFILIATE_CLEAR
});

export function createAffiliate(values) {
  return dispatch => {
    dispatch(createAffiliateBegin());
    let affiliate = createAffiliateFromValues(values);
    return axios.post(`${BASE_API_URL}`,
      JSON.stringify({ affiliate: affiliate }),
      { headers: { 'Content-Type': 'application/json' } }
    )
      .then(json => {
        dispatch(createAffiliateSuccess(json.data));
        return json.data;
      })
      .catch(error => dispatch(createAffiliateFailure(error.response.data)));
  };
}

function createAffiliateFromValues(values) {
  let result = _.pick(values, ['first_name', 'last_name', 'email', 'phone', 'company', 'website', 'commission_pct', 'password']);
  let address = _.pick(values, ['address1', 'address2', 'city', 'region', 'postal_code', 'country']);
  if (_.isEmpty(address) === false) {
    result.address_attributes = address;
    if (values.address_id !== undefined) {
      result.address_attributes.id = values.address_id
    }
  }
  return result;
}