import React from "react"
import { BASE_ACCOUNT_ROUTES } from '../util/routes';

import PageHeader from './shared/PageHeader';

import AccountMenu from './account/AccountMenu';
import ContactForm from './account/ContactForm';

const TITLE = 'Account settings'

class Account extends React.Component {
  render() {
    const { mobileView } = this.props;

    return (
      <>
        <PageHeader title={TITLE} routes={BASE_ACCOUNT_ROUTES} />
        <div className="content-holder" style={{ background: '#fff' }}>
          <AccountMenu nav='contact' />
          <div style={{ display: 'flex' }}>
            <ContactForm mobileView={mobileView} />
          </div>
        </div>
      </>
    );
  }
}

export default Account