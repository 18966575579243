import {
  FETCH_DASHBOARD_BEGIN,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILURE
} from '../actions/dashboardActions';

const initialState = {
  dashboard: {},
  loading: false,
  error: null
};

export default function dashboardeducer(state = initialState, action) {
  switch(action.type) {
    case FETCH_DASHBOARD_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboard: action.payload.dashboard,
      };

    case FETCH_DASHBOARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        dashboard: {}
      };

    default:
      return state;
  }
}