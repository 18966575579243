import React from "react"
import { connect } from "react-redux";
import { BASE_ACCOUNT_ROUTES } from '../../util/routes';
import { updateProfile, updateProfileClear } from "../../actions/profileActions";
import { Form, Row, Col, Button, message, Input } from 'antd';
import ErrorDisplay from '../../components/shared/ErrorDisplay';
import PageHeader from '../shared/PageHeader';
import AccountMenu from "./AccountMenu";

const TITLE = 'System Access'

class SystemAccess extends React.Component {

  state = {
    password: null,
  }

  onFinish = values => {
    this.props.dispatch(updateProfile(values));
  };

  handlePasswordChange = e => {
    this.setState({ password: e.target.value })
  };

  render() {
    const { loading, error, success } = this.props;
    const { password } = this.state;
    const passwordEntered = password && password !== '';

    if (success) {
      this.props.dispatch(updateProfileClear());
      message.success(`Profile update successfully`);
    }
    return (
      <>
        <PageHeader title={TITLE} routes={BASE_ACCOUNT_ROUTES} />
        <div className="content-holder" style={{ background: '#fff' }}>
          <AccountMenu nav='access' />
          <div style={{ display: 'flex' }}>
            <Form layout="vertical" onFinish={this.onFinish}>
              <ErrorDisplay error={error} />
              <Row gutter={16}>
                <Col sm={8} xs={24}>
                  <Form.Item label="Current Password" name="current_password" rules={[{ required: passwordEntered, message: 'Please enter current password' }]}>
                    <Input.Password />
                  </Form.Item>
                </Col>
                <Col sm={8} xs={24}>
                  <Form.Item label="Password" name="password">
                    <Input.Password onChange={this.handlePasswordChange} />
                  </Form.Item>
                </Col>
                <Col sm={8} xs={24}>
                  <Form.Item label="Confirm password" name="password_confirmation" rules={[{ required: passwordEntered, message: 'Please enter password confirmation' }]}>
                    <Input.Password />
                  </Form.Item>
                </Col>
              </Row>
              <Button htmlType="submit" type="primary" loading={loading} disabled={!passwordEntered}>
                Save
              </Button>
            </Form>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.profile.loading,
  error: state.profile.error,
  success: state.profile.success
});

export default connect(mapStateToProps)(SystemAccess);