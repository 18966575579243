import React from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Layout, Menu } from 'antd';
import {
  HomeOutlined, CloseOutlined, ControlOutlined, DownloadOutlined, LinkOutlined, DollarOutlined, ExportOutlined, InteractionOutlined
} from '@ant-design/icons';
import { fetchProfile } from '../../actions/profileActions'
import logo from '../../images/logo_v3.png'

const { Sider } = Layout;

class Sidebar extends React.Component {

  componentDidMount() {
    this.props.dispatch(fetchProfile());
  }

  getActivePathname() {
    return window.location.pathname;
  }

  getActiveParentMenu(activeRoute) {
    return [];
  }

  getActiveRoute = () => {
    let activeRoute = `/${this.props.route.split('/')[1]}`;
    if (activeRoute === '/subscriptions') {
      activeRoute = '/contacts';
    }
    if (activeRoute === '/tools') {
      activeRoute = '/marketing';
    }
    if (activeRoute === '/undefined') {
      activeRoute = '/';
    }
    return activeRoute;
  }

  render() {
    const { user, mobileView, open } = this.props;
    let activeRoute = this.getActiveRoute();

    return (
      <Sider
        theme={'light'}
        breakpoint="md"
        collapsedWidth="0"
        collapsed={mobileView && !open}
        onBreakpoint={broken => {
          this.props.onBreakPoint(broken)
        }}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          zIndex: 3,
        }}>
        {mobileView &&
          <CloseOutlined
            onClick={this.props.closeSidebar}
            style={{ fontSize: '20px', marginTop: '10px', marginLeft: '10px' }}
          />
        }
        <div className="logo">
          <img src={logo} alt="Umbrella"/>
        </div>
        <div style={{ textAlign: 'center', padding: '20px 0' }}>
          <p style={{ marginTop: '10px' }}>{user && `${user.first_name} ${user.last_name}`}</p>
        </div>
        <Menu theme="light"
          defaultSelectedKeys={[this.getActivePathname()]}
          defaultOpenKeys={this.getActiveParentMenu(activeRoute)}
          onSelect={this.props.closeSidebar}
          mode="inline" selectedKeys={[activeRoute]}>
          <Menu.Item key={'/'}>
            <HomeOutlined />
            <span>Dashboard</span>
            <Link to={'/'} />
          </Menu.Item>
          <Menu.Item key={'/links'}>
            <LinkOutlined />
            <span>Links</span>
            <Link to={'/links'} />
          </Menu.Item>
          <Menu.Item key={'/conversions'}>
            <DollarOutlined />
            <span>Transactions</span>
            <Link to={'/conversions'} />
          </Menu.Item>
          <Menu.Item key={'/subscriptions'}>
            <InteractionOutlined />
            <span>Subscriptions</span>
            <Link to={'/subscriptions'} />
          </Menu.Item>
          <Menu.Item key={'/payouts'}>
            <ExportOutlined />
            <span>Payouts</span>
            <Link to={'/payouts'} />
          </Menu.Item>
          <Menu.Item key={'/marketing'}>
            <DownloadOutlined />
            <span>Marketing</span>
            <Link to={'/marketing'} />
          </Menu.Item>
          <Menu.Item key={'/account'}>
            <ControlOutlined />
            <span>Account settings</span>
            <Link to={'/account'} />
          </Menu.Item>
        </Menu>
      </Sider>
    );
  }
}

const mapStateToProps = state => ({
  user: state.profile.profile,
});
export default connect(mapStateToProps)(Sidebar);