import { buildSortString, buildFilterString } from '../util/api';
import axios from 'axios';
import { authHeader } from '../util/api';

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export const FETCH_PAYOUTS_BEGIN   = 'FETCH_PAYOUTS_BEGIN';
export const FETCH_PAYOUTS_SUCCESS = 'FETCH_PAYOUTS_SUCCESS';
export const FETCH_PAYOUTS_FAILURE = 'FETCH_PAYOUTS_FAILURE';

export const fetchPayoutsBegin = () => ({
  type: FETCH_PAYOUTS_BEGIN
});

export const fetchPayoutsSuccess = (payouts, totalItems) => ({
  type: FETCH_PAYOUTS_SUCCESS,
  payload: { payouts, totalItems }
});

export const fetchPayoutsFailure = error => ({
  type: FETCH_PAYOUTS_FAILURE,
  payload: { error }
});

export function fetchPayouts(page = 1, pageSize = 10, filters, sorter) {
  return dispatch => {
    dispatch(fetchPayoutsBegin());
    let sortString = buildSortString(sorter);
    let filterString = buildFilterString(filters);
    return axios.get(`${BASE_API_URL}/payouts.json?page=${page}${sortString}${filterString}`, { headers: authHeader() })
      .then(json => {
        dispatch(fetchPayoutsSuccess(json.data.payouts, json.data.total_entries));
        return json.data.payouts;
      })
      .catch(error => dispatch(fetchPayoutsFailure(error)));
  };
}
