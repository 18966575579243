import { buildSortString, buildFilterString } from '../util/api';
import axios from 'axios';
import {  authHeader } from '../util/api';

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export const FETCH_CONVERSIONS_BEGIN   = 'FETCH_CONVERSIONS_BEGIN';
export const FETCH_CONVERSIONS_SUCCESS = 'FETCH_CONVERSIONS_SUCCESS';
export const FETCH_CONVERSIONS_FAILURE = 'FETCH_CONVERSIONS_FAILURE';

export const fetchConversionsBegin = () => ({
  type: FETCH_CONVERSIONS_BEGIN
});

export const fetchConversionsSuccess = (conversions, availableProducts, totalItems) => ({
  type: FETCH_CONVERSIONS_SUCCESS,
  payload: { conversions, availableProducts, totalItems }
});

export const fetchConversionsFailure = error => ({
  type: FETCH_CONVERSIONS_FAILURE,
  payload: { error }
});

export function fetchConversions(page = 1, pageSize = 10, filters, sorter) {
  return dispatch => {
    dispatch(fetchConversionsBegin());
    let sortString = buildSortString(sorter);
    let filterString = buildFilterString(filters);
    return axios.get(`${BASE_API_URL}/conversions.json?page=${page}&per_page=${pageSize}${sortString}${filterString}`, { headers: authHeader() })
      .then(json => {
        dispatch(fetchConversionsSuccess(json.data.conversions, json.data.available_products, json.data.total_entries));
        return json.data.conversions;
      })
      .catch(error => dispatch(fetchConversionsFailure(error)));
  };
}