import { authHeader } from '../util/api';
import _ from 'lodash';
import axios from 'axios';

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export const FETCH_PROFILE_BEGIN = 'FETCH_PROFILE_BEGIN';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_FAILURE = 'FETCH_PROFILE_FAILURE';

export const UPDATE_PROFILE_BEGIN = 'UPDATE_PROFILE_BEGIN';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';
export const UPDATE_PROFILE_CLEAR = 'UPDATE_PROFILE_CLEAR';

export const fetchProfileBegin = () => ({
  type: FETCH_PROFILE_BEGIN
});

export const fetchProfileSuccess = (profile) => ({
  type: FETCH_PROFILE_SUCCESS,
  payload: { profile }
});

export const fetchProfileFailure = error => ({
  type: FETCH_PROFILE_FAILURE,
  payload: { error }
});

export function fetchProfile() {
  return dispatch => {
    dispatch(fetchProfileBegin());
    return axios.get(`${BASE_API_URL}/profile.json`, {
      headers: authHeader()
    })
      .then(json => {
        dispatch(fetchProfileSuccess(json.data));
        return json.data;
      })
      .catch(error => dispatch(fetchProfileFailure(error)));
  };
}

export const updateProfileBegin = () => ({
  type: UPDATE_PROFILE_BEGIN
});

export const updateProfileSuccess = (profile) => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: { profile }
});

export const updateProfileFailure = error => ({
  type: UPDATE_PROFILE_FAILURE,
  payload: { error }
});

export const updateProfileClear = () => ({
  type: UPDATE_PROFILE_CLEAR
});

export function updateProfile(values) {
  return dispatch => {
    dispatch(updateProfileBegin());
    return axios.put(`${BASE_API_URL}/profile.json`,
      JSON.stringify({ profile: createProfileFromValues(values) }),
      { headers: authHeader() })
      .then(json => {
        dispatch(updateProfileSuccess(json.data));
        return json.data;
      })
      .catch(error => dispatch(updateProfileFailure(error.response.data)));
  };
}

function createProfileFromValues(values) {
  let result = _.pick(values, ['first_name', 'last_name', 'email', 'phone', 'company', 'website', 'current_password', 'password', 'password_confirmation', 'payout_email_address']);
  let address = _.pick(values, ['address1', 'address2', 'city', 'region', 'postal_code', 'country']);
  if (_.isEmpty(address) === false) {
    result.address_attributes = address;
    if (values.address_id !== undefined) {
      result.address_attributes.id = values.address_id
    }
  }
  return result;
}