import React from "react"
import { Link } from 'react-router-dom'
import { PageHeader as AntPageHeader } from 'antd';
import { Helmet } from 'react-helmet'

function itemRender(route, params, routes, paths) {
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={route.path}>{route.breadcrumbName}</Link>
  );
}

const PageHeader = ({ title, routes }) => {
  return (
    <>
      <Helmet><title>{ title }</title></Helmet>
      <AntPageHeader breadcrumb={{ routes, itemRender }} />
    </>
  );
};

export default PageHeader