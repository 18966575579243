export function authHeader() {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem('user'));

  if (user && user.token) {
    return { 'Authorization': 'Bearer ' + user.token, 'Content-Type': 'application/json' };
  } else {
    return {};
  }
}

// Handle HTTP errors since fetch won't.
export function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export function buildSortString(sorter) {
  let sortString = '';
  if (sorter !== undefined && sorter.field !== undefined) {
    let order = sorter.order === 'ascend' ? 'asc' : 'desc';
    sortString = `&q[s]=${sorter.field}+${order}`
  }
  return sortString;
}

export function buildFilterString(filters) {
  let result = '&';
  if (filters !== undefined) {
    Object.keys(filters).forEach(function (key) {
      if (filters[key]) {
        if (key === 'status' || key === 'state') {
          filters[key].forEach(function (element) {
            result += `&q[invoice_state_in][]=${element}`;
          });
        }
        if (key === 'product') {
          filters[key].forEach(function (element) {
            result += `&q[product_slug_in][]=${element}`;
          });
        }
        if (key === 'kind') {
          filters[key].forEach(function (element) {
            result += `&q[kind_eq]=${element}`;
          });
        }
        if (key === 'link_product') {
          filters[key].forEach(function (element) {
            result += `&q[session_link_product_slug_in][]=${element}`;
          });
        }
        if (key === 'affiliate_session_link_product_name') {
          filters[key].forEach(function (element) {
            result += `&q[affiliate_session_link_product_slug_in][]=${element}`;
          });
        }
        if (key === 'affiliate_session_subscription_state') {
          filters[key].forEach(function (element) {
            result += `&q[state_in][]=${element}`;
          });
        }
        if (key === 'created_at') {
          result += `&q[created_at_gteq]=${filters[key][0]}&q[created_at_lteq]=${filters[key][1]}`;
        }
        if (key === 'date') {
          result += `&q[date_gteq]=${filters[key][0]}&q[date_lteq]=${filters[key][1]}`;
        }
        if (key === 'updated_at') {
          result += `&q[updated_at_gteq]=${filters[key][0]}&q[updated_at_lteq]=${filters[key][1]}`;
        }
      }
    });
  }
  return result;
}
