import React, { useState } from "react"
import { Form, Input, Row, Col } from 'antd';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

const NestedAddressForm = (props) => {
  const [country, setCountry] = useState('US');
  const [region, setRegion] = useState({});

  return (
    <>
      <Form.Item name='address1' rules={[{ required: true, message: 'Please enter a street address' }]}>
        <Input placeholder="Street Address" />
      </Form.Item>
      <Form.Item name='address2'>
        <Input placeholder="Line 2" />
      </Form.Item>
      <Row gutter={16}>
        <Col sm={12} xs={12}>
          <Form.Item name='city' rules={[{ required: true, message: 'Please enter a city' }]}>
            <Input placeholder="City" />
          </Form.Item>
        </Col>
        <Col sm={12} xs={12}>
          <Form.Item name='postal_code' rules={[{ required: true, message: 'Please enter a postal code' }]}>
            <Input placeholder="Postal Code" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col sm={12} xs={12}>
          <Form.Item name='country' rules={[{ required: true, message: 'Please enter a country' }]}>
            <CountryDropdown
              classes={'ant-select'}
              valueType='short'
              onChange={(val) => setCountry(val)} />
          </Form.Item>
        </Col>
        <Col sm={12} xs={12}>
          <Form.Item name='region' rules={[{ required: true, message: 'Please select a region' }]}>
            <RegionDropdown
              country={country} countryValueType={'short'}
              valueType='short'
              classes={'ant-select'}
              onChange={(val) => setRegion(val)} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );

}

export default NestedAddressForm