import React from "react"
import { connect } from "react-redux";
import { BASE_ACCOUNT_ROUTES } from '../../util/routes';
import { Skeleton, message, Form, Row, Col, Button } from 'antd';
import ErrorDisplay from '../../components/shared/ErrorDisplay';
import { fetchProfile, updateProfileClear, updateProfile } from "../../actions/profileActions";
import InputField from '../shared/inputs/InputField';
import PageHeader from '../shared/PageHeader';
import AccountMenu from "./AccountMenu";

const TITLE = 'Payout information'

class PayoutInformation extends React.Component {

  componentDidMount() {
    this.props.dispatch(fetchProfile());
  }

  onFinish = values => {
    this.props.dispatch(updateProfile(values));
  };

  render() {
    const { profile, error, success, loading } = this.props;

    if (success) {
      this.props.dispatch(updateProfileClear());
      message.success(`Profile updated successfully`);
    }

    if (profile === null || profile === undefined) {
      return (
        <Skeleton />
      )
    }
    else {
      return (
        <>
          <PageHeader title={TITLE} routes={BASE_ACCOUNT_ROUTES} />
          <div className="content-holder" style={{ background: '#fff' }}>
            <AccountMenu nav='payout' />
            <div style={{ display: 'flex' }}>
              <Form layout="vertical" onFinish={this.onFinish} initialValues={profile}>
                <ErrorDisplay error={error} />
                <InputField label="Email address" attribute="payout_email_address" 
              help="The email address used for accepting PayPal or Payoneer payments" />
                <Button htmlType="submit" type="primary" loading={loading}>
                  Save
                </Button>
              </Form>
            </div>
          </div>
        </>
      );
    }
  }
}

const mapStateToProps = state => ({
  profile: state.profile.profile,
  loading: state.profile.loading,
  error: state.profile.error,
  success: state.profile.success
});

export default connect(mapStateToProps)(PayoutInformation);