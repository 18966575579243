import React, { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { Layout } from 'antd';
import { Helmet } from "react-helmet";

import Header from '../layout/Header'
import Sidebar from '../layout/Sidebar'
import ErrorBoundary from './ErrorBoundary';

const { Content, Footer } = Layout;

export const PrivateRoute = ({ component: Component, ...rest }) => {

  const [mobileView, setMobileView] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);

  return (
    <Route {...rest} render={props => (
      localStorage.getItem('user')
        ?
        <>
          <Helmet titleTemplate="%s | Umbrella">
            <title></title>
          </Helmet>
          <ErrorBoundary>
            <Layout>
              <Sidebar
                route={window.location.pathname.replace("/app", "")}
                onBreakPoint={(value) => setMobileView(value)}
                mobileView={mobileView}
                open={openSidebar}
                closeSidebar={() => setOpenSidebar(false)}
              />
              <Layout>
                <Header mobileView={mobileView} sidebarOpen={openSidebar} openSidebar={(value) => setOpenSidebar(value)} />
                <Content style={{ minHeight: '100vh' }}>
                  <div style={{ padding: mobileView ? '20px 20px 0px 20px' : '20px 20px 0px 220px', marginTop: 64 }}>
                    <Component {...props} mobileView={mobileView} />
                  </div>
                  {mobileView && openSidebar &&
                    <div
                      onClick={() => setOpenSidebar(false)}
                      style={{
                        position: 'fixed',
                        zIndex: 2,
                        width: '100%',
                        height: '100vh',
                        background: "rgba(0,0,0,0.4)",
                        top: 0
                      }}>
                    </div>
                  }
                </Content>
                <Footer style={{ textAlign: 'center' }}>Umbrella Micro Enterprises 2023</Footer>
              </Layout>
            </Layout>
          </ErrorBoundary>
        </>
        : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
  )
}

export default PrivateRoute