import React from "react"
import PropTypes from "prop-types"
import { Table, Tag } from 'antd';
import moment from 'moment';

const statusColorMappings = {
  active: "green",
  deactivated: "gray",
};

class List extends React.Component {
  state = {
    pagination: {
      current: 1,
      showSizeChanger: true,
      total: this.props.totalSubscriptions,
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    },
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState({
      pagination: pager,
    });
    this.props.onTableChange(pagination, filters, sorter);
  };

  render() {
    const { loading, subscriptions, availableProducts } = this.props;

    const productFilters = availableProducts.map((product) => ({
      text: product.name,
      value: product.slug,
    }))

    const statusFilters = [
      {
        text: 'active',
        value: 'active',
      },
      {
        text: 'deactivated',
        value: 'deactivated',
      },
    ]

    let columns = [
      {
        title: "Subscription ID",
        dataIndex: "id",
        render: (status, record) => record.id,
        sorter: true,
      },
      {
        title: "Name",
        dataIndex: "user",
        render: (user) => user.name,
      },
      {
        title: "Email",
        dataIndex: "user",
        render: (user) => user.email,
      },
      {
        title: "Product",
        dataIndex: "affiliate_session_link_product_name",
        render: (product, record) => record.product.name,
        filterMultiple: true,
        filters: productFilters,
        sorter: true,
      },
      {
        title: "Invoice Count",
        dataIndex: "affiliate_session_subscription_invoice_count",
        render: (invoice_count, record) => record.invoice_count
      },
      {
        title: "Status",
        dataIndex: "affiliate_session_subscription_state",
        render: (status, record) => (
          <Tag color={statusColorMappings[record.status]} key={record.status}>
            {record.status}
          </Tag>
        ),
        filterMultiple: true,
        filters: statusFilters,
        sorter: true,
      },
      {
        title: "Created Date",
        dataIndex: "created_at",
        render: (created_at, record) => moment(created_at).format('MMM DD, YYYY'),
        sorter: true,
      },
      {
        title: "Cancelled Date",
        dataIndex: "deactivated_at",
        render: (deactivated_at, record) => deactivated_at ? moment(deactivated_at).format('MMM DD, YYYY') : 'NA',
        sorter: true,
      },
    ];

    const { pagination } = this.state;
    pagination.total = this.props.totalSubscriptions;
    pagination.current = this.props.page;

    return (
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={subscriptions}
        pagination={pagination}
        loading={loading}
        onChange={this.handleTableChange}
        size="middle"
        scroll={{ x: true }}
      />
    );
  }
}

List.propTypes = {
  totalSubscriptions: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  subscriptions: PropTypes.array.isRequired,
  onTableChange: PropTypes.func.isRequired,
};

List.defaultProps = {
  totalSubscriptions: 0,
  page: 0,
  loading: true,
  subscriptions: [],
};

export default List;
