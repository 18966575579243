import React from "react";
import _ from 'lodash';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { BASE_SUBSCRIPTION_ROUTES } from "../util/routes";

import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import PageHeader from "./shared/PageHeader";
import List from "./subscriptions/List";
import { fetchSubscriptions } from "../actions/subscriptionActions";

const TITLE = "Subscriptions";

class Subscriptions extends React.Component {
  state = {
    page: 1,
    pageSize: 10,
    filters: {},
    sorter: {},
    query: "",
  };

  componentDidMount() {
    this.props.dispatch(fetchSubscriptions());
  }

  handleTableChange = (pagination, filters, sorter) => {
    const page = pagination.current;
    const pageSize = pagination.pageSize;
    let { query } = this.state;
    this.props.dispatch(
      fetchSubscriptions(page, pageSize, filters, sorter, query)
    );
    this.setState({
      page,
      filters,
      sorter,
      pageSize,
    });
  };

  handleSearch = _.debounce((query) => {
    let { filters, sorter, pageSize } = this.state;
    this.props.dispatch(fetchSubscriptions(1, pageSize, filters, sorter, query));
  }, 300);


  render() {
    const { loading, subscriptions, availableProducts, totalItems } =
      this.props;
    const { page } = this.state;

    return (
      <>
        <PageHeader title={TITLE} routes={BASE_SUBSCRIPTION_ROUTES} />
        <div className="content-holder" style={{ background: "#fff" }}>
          <Input
            placeholder="Search by name or email"
            onChange={(e) => this.handleSearch(e.target.value)}
            suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          />
          <List
            subscriptions={subscriptions}
            availableProducts={availableProducts}
            totalSubscriptions={totalItems}
            onTableChange={this.handleTableChange}
            loading={loading}
            page={page}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  subscriptions: state.subscriptions.items,
  availableProducts: state.subscriptions.availableProducts,
  totalItems: state.subscriptions.totalItems,
  loading: state.subscriptions.loading,
});

export default connect(mapStateToProps)(withRouter(Subscriptions));
