import React from "react"
import PageHeader from './shared/PageHeader';
import MarketingResources from './training/MarketingResources';
import { BASE_MARKETING_ROUTES } from '../util/routes';

const TITLE = 'Marketing'

class Marketing extends React.Component {
  render() {
    const { mobileView } = this.props

    return (
      <>
        <PageHeader title={TITLE} routes={BASE_MARKETING_ROUTES} />
        <div className="content-holder white">
          <MarketingResources mobileView={mobileView} />
        </div>
      </>
    );
  }
}

export default Marketing
