import React from "react"
import moment from 'moment'
import PropTypes from "prop-types"
import { Table, DatePicker, Space, Button } from 'antd';
import { SearchOutlined, FilterFilled } from "@ant-design/icons";
import CurrencyFormat from "react-currency-format";

class List extends React.Component {
  state = {
    data: this.props.contacts,
    pagination: {
      current: 1,
      showSizeChanger: true,
      total: this.props.totalPayouts,
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
    }
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Space direction="vertical">
          <DatePicker.RangePicker
            ref={node => {
              this.searchInput = node;
            }}
            onChange={e => setSelectedKeys(e !== null ? [e[0].format("DD/MM/YYYY"), e[1].format("DD/MM/YYYY")] : [])}
            placeholder={`Search ${dataIndex}`}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8 }}
          />
          <Space>
            <Button
              type="primary"
              onClick={confirm}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </Space>
      </div>
    ),
    filterIcon: filtered => <FilterFilled style={{ color: filtered ? '#1890ff' : undefined }} />,
  });

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState({
      pagination: pager,
    });
    this.props.onTableChange(pagination, filters, sorter);
  };

  render() {
    const { loading, payouts } = this.props;

    let columns = [
      {
        title: 'Date',
        dataIndex: 'date',
        sorter: true,
        render: (date) => moment(date).format('MM/DD/YYYY'),
        ...this.getColumnSearchProps('date'),
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        sorter: true,
        render: (amount) => <CurrencyFormat value={amount} displayType='text' prefix={'$'} decimalScale={2} fixedDecimalScale={amount % 1 !== 0} thousandSeparator />
      },
      {
        title: 'Payment method',
        dataIndex: 'payment_method',
      },
      
    ];

    const { pagination } = this.state;
    pagination.total = this.props.totalPayouts;
    pagination.current = this.props.page;

    return (
      <Table
        columns={columns}
        rowKey={record => record.id}
        dataSource={payouts}
        pagination={pagination}
        loading={loading}
        onChange={this.handleTableChange}
        size="middle"
        scroll={{ x: true }}
      />
    );
  }
}

List.propTypes = {
  totalPayouts: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  payouts: PropTypes.array.isRequired,
  onTableChange: PropTypes.func.isRequired,
};

List.defaultProps = {
  totalPayouts: 0,
  page: 0,
  loading: true,
  payouts: [],
  availableProducts: [],
};

export default List;