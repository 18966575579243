import {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  FORGOT_PASSWORD_BEGIN,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_BEGIN,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  LOGOUT
} from '../actions/userActions';

let user = JSON.parse(localStorage.getItem('user'));

const initialState = {
  user: user,
  loading: false,
  error: null,
  success: false,
};

export default function authReducer(state = initialState, action) {
  switch(action.type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case FORGOT_PASSWORD_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
      };

    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case RESET_PASSWORD_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        success: true
      };

    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    
    case LOGOUT:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
      };

    default:
      return state;
  }
}