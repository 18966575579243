import axios from 'axios';
import { authHeader } from '../util/api';

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export const ACCEPT_TERM_BEGIN = 'ACCEPT_TERM_BEGIN';
export const ACCEPT_TERM_SUCCESS = 'ACCEPT_TERM_SUCCESS';
export const ACCEPT_TERM_FAILURE = 'ACCEPT_TERM_FAILURE';
export const ACCEPT_TERM_CLEAR = 'ACCEPT_TERM_CLEAR';

export const acceptTermBegin = () => ({
  type: ACCEPT_TERM_BEGIN
});

export const acceptTermSuccess = () => ({
  type: ACCEPT_TERM_SUCCESS,
});

export const acceptTermFailure = error => ({
  type: ACCEPT_TERM_FAILURE,
  payload: { error }
});

export const acceptTermClear = () => ({
  type: ACCEPT_TERM_CLEAR
});

export function acceptTerm() {
  return dispatch => {
    dispatch(acceptTermBegin());
    return axios.post(`${BASE_API_URL}/terms_acceptance.json`, null,
      { headers: authHeader() }
    )
      .then(json => {
        dispatch(acceptTermSuccess());
        return json.data;
      })
      .catch(error => dispatch(acceptTermFailure(error.response.data)));
  };
}

