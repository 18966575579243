import {
  CREATE_AFFILIATE_BEGIN,
  CREATE_AFFILIATE_FAILURE,
  CREATE_AFFILIATE_SUCCESS,
  CREATE_AFFILIATE_CLEAR,
} from '../actions/affiliateActions';

const initialState = {
  loading: false,
  error: null,
  affiliate: null,
  success: null,
};

export default function affiliateReducer(state = initialState, action) {
  switch(action.type) {
    case CREATE_AFFILIATE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case CREATE_AFFILIATE_SUCCESS:
      return {
        ...state,
        loading: false,
        affiliate: action.payload.affiliate,
        success: true
      };

    case CREATE_AFFILIATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case CREATE_AFFILIATE_CLEAR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}