import axios from 'axios';

const BASE_LOGIN_URL = process.env.REACT_APP_BASE_LOGIN_URL;

export const LOGIN_BEGIN   = 'LOGIN_BEGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const FORGOT_PASSWORD_BEGIN   = 'FORGOT_PASSWORD_BEGIN';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const RESET_PASSWORD_BEGIN   = 'RESET_PASSWORD_BEGIN';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';


export const LOGOUT = 'LOGOUT';

export const loginBegin = () => ({
  type: LOGIN_BEGIN
});

export const loginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  payload: { user: user }
});

export const loginFailure = error => ({
  type: LOGIN_FAILURE,
  payload: { error }
});

export const forgotPasswordBegin = () => ({
  type: FORGOT_PASSWORD_BEGIN
});

export const forgotPasswordSuccess = (user) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: { user: user }
});

export const forgotPasswordFailure = error => ({
  type: FORGOT_PASSWORD_FAILURE,
  payload: { error }
});

export const resetPasswordBegin = () => ({
  type: RESET_PASSWORD_BEGIN
});

export const resetPasswordSuccess = (user) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: { user: user }
});

export const resetPasswordFailure = error => ({
  type: RESET_PASSWORD_FAILURE,
  payload: { error }
});

export const logoutSuccess = () => ({
  type: LOGOUT,
  payload: { user: null }
});

export function logout() {
  return dispatch => {
    localStorage.removeItem('user');
    dispatch(logoutSuccess());
  };
}

export function login(email, password) {
  return dispatch => {
    dispatch(loginBegin());
    let user = { email, password }
    return axios.post(`${BASE_LOGIN_URL}/login`, 
      JSON.stringify({api_v2_affiliate: user}), {
      headers: { 'Content-Type': 'application/json' },
      })
      .then((json) => {
        let data = json.data;
        data.token = json.headers.authorization.split(' ')[1];
        localStorage.setItem('user', JSON.stringify(data));
        dispatch(loginSuccess(data));
        return data
      })
      .catch(error => dispatch(loginFailure(error)));
  };
}

export function forgotPassword(email) {
  return dispatch => {
    dispatch(forgotPasswordBegin());
    let user = { email }
    return axios.post(`${BASE_LOGIN_URL}/password`, 
      JSON.stringify({api_v2_affiliate: user}), {
      headers: { 'Content-Type': 'application/json' },
      })
      .then((json) => {
        let data = json.data;
        dispatch(forgotPasswordSuccess(data));
        return data
      })
      .catch(error => dispatch(forgotPasswordFailure(error)));
  };
}

export function resetPassword(values) {
  return dispatch => {
    dispatch(resetPasswordBegin());
    let credentials = values
    return axios.put(`${BASE_LOGIN_URL}/password`, 
      JSON.stringify({api_v2_affiliate: credentials}), {
      headers: { 'Content-Type': 'application/json' },
      })
      .then((json) => {
        let data = json.data;
        if(data.reset_password_token === undefined){
          dispatch(resetPasswordSuccess(data));
          return data
        } else {
          dispatch(resetPasswordFailure(data))
        }
      })
      .catch(error => dispatch(resetPasswordFailure(error)));
  };
}

